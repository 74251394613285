import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchMissionLogList(params) {
  return axios.get(baseUrl + baseApiVersion + `/mission/log/list`, {
    headers,
    params,
  });
}

export function fetchMissionLogInfo(logId) {
  return axios.get(baseUrl + baseApiVersion + `/mission/log/${logId}`, {
    headers,
  });
}